import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './ReplicaMode.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade } from 'swiper/core';

import { ReplicaModeContext } from './ReplicaModeContext';
import { useHotspotAnimation } from './Pages.hooks';
import { Page } from '../../../components/canvasflow/article/Replica';
import { AppContext } from '../../../services/AppContext';

import { ActionBar } from './ActionBar';

SwiperCore.use([EffectFade]);

// Import Swiper styles
import 'swiper/swiper.scss';

export function SinglePage({ data, selected }) {
	const {
		onArticleChange,
		selectedIndex,
		setSelectedIndex,
		setSelected,
		setSwiper,
		pageMap,
	} = useContext(ReplicaModeContext);
	const [pageIndexMapping, setPageIndexMapping] = useState(null);
	const [slides, setSlides] = useState([]);

	useEffect(() => {
		localStorage.setItem('page-spread-mode', 'single');
	}, []);

	useEffect(() => {
		if (!pageMap) {
			return;
		}

		console.log(`DATA`, data);

		const newPageIndexMapping = data.reduce((acc, data, index) => {
			const { pageIndex } = data;
			acc.set(index, pageIndex);
			return acc;
		}, new Map());

		const newIndex = getPageIndexFromSelectedArticle(selected, data);
		setPageIndexMapping(newPageIndexMapping);
		setSelectedIndex(newIndex);
	}, [pageMap]);

	useHotspotAnimation('single', selectedIndex, pageIndexMapping);

	useEffect(() => {
		if (selectedIndex === null) {
			return null;
		}
		if (pageIndexMapping === null) {
			return;
		}
		console.log(`Trigger animation`);
		setSlides(
			data.map(({ page, article, replica }, index) => (
				<SwiperSlide key={`id-${index}`} id={`slide-number-${index}`}>
					<div className={styles['page']}>
						{selected.id === article.id ||
						selectedIndex === index ||
						index === selectedIndex - 1 ||
						index === selectedIndex - 2 ||
						index === selectedIndex + 1 ||
						index === selectedIndex + 2 ? (
							<>
								<SinglePageImage
									identifier={`page-${index}`}
									src={page}
									article={article}
									replica={replica}
								/>
							</>
						) : null}
					</div>
				</SwiperSlide>
			))
		);
		const newIndex = pageIndexMapping.get(selectedIndex);
		const selectedArticle = pageMap.get(newIndex);

		if (selectedArticle) {
			if (selected.id !== selectedArticle.id) {
				onArticleChange(selectedArticle);
				setSelected(selectedArticle);
			}
		}
	}, [selectedIndex, pageIndexMapping]);

	if (selectedIndex === null || !pageMap || pageIndexMapping === null) {
		return null;
	}

	const className = [styles['pages'], styles['single-page']];
	return (
		<div className={className.join(' ')}>
			{data.length ? (
				<Swiper
					updateOnWindowResize={true}
					initialSlide={selectedIndex}
					scrollbar={{
						draggable: false,
					}}
					onSwiper={(s) => {
						setSwiper(s);
					}}
					onSlideChange={(s) => {
						const { activeIndex } = s;
						setSelectedIndex(activeIndex);
					}}
				>
					{slides}
				</Swiper>
			) : null}
		</div>
	);
}

SinglePage.propTypes = {
	selected: PropTypes.any,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			index: PropTypes.number,
			pageIndex: PropTypes.number,
			article: PropTypes.any,
			page: PropTypes.string,
		})
	),
};

function SinglePageImage({ src, identifier, article, replica }) {
	const { onClickHotspot } = useContext(ReplicaModeContext);
	const { orientation, isTouchDevice, config } = useContext(AppContext);
	const [style, setStyle] = useState({});

	useEffect(() => {
		if (isTouchDevice) {
			setStyle({ pointerEvents: 'none' });
		}

		if (orientation === 'portrait') {
			setStyle({
				...style,
				width: '100%',
				maxWidth: null,
				height: null,
				maxHeight: '100%',
			});
		} else {
			setStyle({
				...style,
				width: null,
				maxWidth: '100%',
				height: '100%',
				maxHeight: null,
			});
		}
	}, [orientation, isTouchDevice]);

	if (!isTouchDevice && config?.replica?.zoom?.enabled) {
		return (
			<ZoomPageImage
				identifier={identifier}
				src={src}
				direction={'left'}
				article={article}
				replica={replica}
			/>
		);
	}

	const { isAdvert, advert } = getIsAdvert(article);
	let onClick = () => {};
	let classNames = [];

	if (isAdvert && advert.imagelink && isValidUrl(advert.imagelink)) {
		classNames.push(styles['clickable']);
		onClick = () => {
			window.open(advert.imagelink);
		};
	}

	/*
		<img
				className={classNames.join()}
				onClick={onClick}
				draggable={false}
				src={src}
			/>
	*/

	return (
		<figure
			id={identifier}
			data-step={1}
			className={classNames.join()}
			onMouseMove={() => {}}
			style={style}
			onClick={(e) => {
				if (e.target === e.currentTarget) {
					onClick();
				}
			}}
		>
			<Page
				name={replica.name}
				page={replica.page}
				hotspots={replica.hotspots}
				image={replica.image}
				onClickHotspot={onClickHotspot}
			/>

			<ActionBar
				page={src}
				direction={'left'}
				isZoomPage={false}
				setIsZoomPage={() => {}}
			/>
		</figure>
	);
}

SinglePageImage.propTypes = {
	identifier: PropTypes.string,
	src: PropTypes.string,
	article: PropTypes.any,
	replica: PropTypes.any,
};

export function DoublePages({ data, selected }) {
	const {
		selectedIndex,
		setSelectedIndex,
		onArticleChange,
		setSwiper,
		setSelected,
		pageMap,
	} = useContext(ReplicaModeContext);
	const [pageIndexMapping, setPageIndexMapping] = useState(null);
	const [slides, setSlides] = useState([]);

	useEffect(() => {
		console.log(`DOUBLE PAGES DATA`, data);
		localStorage.setItem('page-spread-mode', 'double');
	}, []);

	useEffect(() => {
		if (!pageMap || !selected) {
			return;
		}

		const newPageIndexMapping = data.reduce((acc, data, index) => {
			const { pageIndex } = data;
			acc.set(index, pageIndex);
			return acc;
		}, new Map());

		const newIndex = getPageIndexFromSelectedArticle(selected, data);
		setPageIndexMapping(newPageIndexMapping);
		setSelectedIndex(newIndex);
	}, [pageMap]);

	useHotspotAnimation('double', selectedIndex, pageIndexMapping);

	useEffect(() => {
		if (selectedIndex === null) {
			return;
		}
		if (pageIndexMapping === null) {
			return;
		}
		setSlides(
			data.map((item, i) => (
				<SwiperSlide key={`id-${i}`} id={`slide-number-${i}`}>
					<DoublePage key={i} index={i} item={item} />
				</SwiperSlide>
			))
		);
		const newIndex = pageIndexMapping.get(selectedIndex);
		const selectedArticle = pageMap.get(newIndex);

		if (selectedArticle) {
			if (selected.id !== selectedArticle.id) {
				onArticleChange(selectedArticle);
				setSelected(selectedArticle);
			}
		}
	}, [selectedIndex, pageIndexMapping]);

	if (!pageMap || pageIndexMapping === null) {
		return null;
	}

	if (selectedIndex === null || !data.length) {
		return null;
	}

	return (
		<div className={styles['double-page-container']}>
			<Swiper
				updateOnWindowResize={true}
				initialSlide={selectedIndex}
				scrollbar={{
					draggable: false,
				}}
				onSwiper={(s) => {
					setSwiper(s);
				}}
				onSlideChange={(s) => {
					const { activeIndex } = s;
					setSelectedIndex(activeIndex);
				}}
			>
				{slides}
			</Swiper>
		</div>
	);
}

DoublePages.propTypes = {
	selected: PropTypes.any,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			index: PropTypes.number,
			pageIndex: PropTypes.number,
			article: PropTypes.any,
			altArticle: PropTypes.any,
			pages: PropTypes.arrayOf(PropTypes.string),
		})
	),
};

function DoublePage({ item, index }) {
	let leftPage = <span />;
	let rightPage = <span />;
	if (item.pages) {
		if (item?.pages[0]) {
			leftPage = (
				<DoublePageImage
					direction="left"
					src={item.replicas[0].image}
					article={item.article}
					replica={item.replicas[0]}
					identifier={`page-image-${index}-left`}
				/>
			);
		}
		if (item?.pages[1]) {
			rightPage = (
				<DoublePageImage
					direction="right"
					article={item.altArticle}
					replica={item.replicas[1]}
					src={item.replicas[1].image}
					identifier={`page-image-${index}-right`}
				/>
			);
		}
	}
	const className = [styles['pages'], styles['double-page']];
	return (
		<div className={styles['double-page-handler']}>
			<div data-index={index} className={className.join(' ')}>
				<div className={styles['left']}>{leftPage}</div>
				<div className={styles['right']}>{rightPage}</div>
			</div>
		</div>
	);
}

DoublePage.propTypes = {
	item: PropTypes.any,
	index: PropTypes.number,
};

function DoublePageImage({ src, identifier, direction, article, replica }) {
	const { onClickHotspot } = useContext(ReplicaModeContext);
	const { orientation, isTouchDevice, config } = useContext(AppContext);
	const [style, setStyle] = useState({});

	const { isAdvert, advert } = getIsAdvert(article);

	useEffect(() => {
		if (isTouchDevice) {
			setStyle({ pointerEvents: 'none' });
		}

		if (orientation === 'portrait') {
			setStyle({
				...style,
				width: '100%',
				maxWidth: null,
				height: null,
				maxHeight: '100%',
			});
		} else {
			setStyle({
				...style,
				width: null,
				maxWidth: '100%',
				height: '100%',
				maxHeight: null,
			});
		}
	}, [orientation, isTouchDevice]);

	if (!isTouchDevice && config?.replica?.zoom?.enabled) {
		return (
			<ZoomPageImage
				identifier={identifier}
				src={src}
				direction={direction}
				article={article}
				replica={replica}
			/>
		);
	}

	// let onClick = () => {};
	let classNames = [];

	if (
		article &&
		isAdvert &&
		advert.imagelink &&
		isValidUrl(advert.imagelink)
	) {
		classNames.push(styles['clickable']);
		/*onClick = () => {
			window.open(advert.imagelink);
		};*/
	}

	return (
		<>
			<figure id={identifier} onMouseMove={() => {}} style={style}>
				<Page
					name={replica.name}
					page={replica.page}
					hotspots={replica.hotspots}
					image={replica.image}
					onClickHotspot={onClickHotspot}
				/>
				<ActionBar
					page={src}
					direction={direction}
					isZoomPage={false}
					setIsZoomPage={() => {}}
				/>
			</figure>
		</>
	);

	/*return (
		<>
			<figure id={identifier} onMouseMove={() => {}} style={style}>
				<img
					className={classNames.join()}
					onClick={onClick}
					draggable={false}
					src={src}
				/>
				<ActionBar
					page={src}
					direction={direction}
					isZoomPage={false}
					setIsZoomPage={() => {}}
				/>
			</figure>
		</>
	);*/
}

DoublePageImage.propTypes = {
	direction: PropTypes.oneOf(['left', 'right']),
	src: PropTypes.string,
	article: PropTypes.any,
	replica: PropTypes.any,
	identifier: PropTypes.string,
};

// TODO Remove this hack this is only for Underseajournal
const HACK_EDITORIAL_LINK = {
	18684: 'https://pros-blog.padi.com/shine-bright-in-the-new-padi-integrated-ecosystem/',
	18686: 'https://dan.org/',
	18698: 'https://register.gotowebinar.com/register/8025365483083273744',
	18703: 'https://www.instagram.com/mermaid_twinkle/',
	18710: 'https://www.padi.com/aware',
	18715: 'https://greenfins.net/action-centre/',
	18716: 'https://www.padi.com/conservation-torchbearer-five',
	18672: 'https://www.xit404.com/',
};

function getIsAdvert(article) {
	if (!article) {
		return {
			isAdvert: false,
			advert: null,
		};
	}

	const hackEditorial = HACK_EDITORIAL_LINK[parseInt(`${article.id}`, 10)];

	if (hackEditorial) {
		return {
			isAdvert: true,
			advert: {
				imagelink: hackEditorial,
			},
		};
	}

	let adverts = article.components.filter((c) => c.component === 'advert');

	let isAdvert = adverts.length > 0;

	return {
		isAdvert,
		advert: isAdvert ? clone(adverts[0]) : null,
	};
}

function ZoomPageImage({ identifier, src, direction, article, replica }) {
	const { onClickHotspot } = useContext(ReplicaModeContext);
	const [isActive, setIsActive] = useState(false);
	const { orientation, config } = useContext(AppContext);
	let zoomIncrement = config?.replica?.zoom?.increment || 200;
	const [style, setStyle] = useState(
		isActive
			? {
					backgroundImage: `url(${src})`,
					backgroundPosition: '0% 0%',
					backgroundSize: `${zoomIncrement}%`,
			  }
			: {
					// pointerEvents: 'none',
			  }
	);

	const { isAdvert, advert } = getIsAdvert(article);

	useEffect(() => {
		if (isActive) {
			setStyle({
				...style,
				backgroundImage: `url(${src})`,
				backgroundPosition: '0% 0%',
				backgroundSize: `${zoomIncrement}%`,
			});
		} else {
			setStyle({
				...style,
				backgroundImage: null,
			});
		}
	}, [isActive]);

	useEffect(() => {
		if (orientation === 'portrait') {
			setStyle({
				...style,
				width: '100%',
				maxWidth: null,
				height: null,
				maxHeight: '100%',
			});
		} else {
			setStyle({
				...style,
				width: null,
				maxWidth: '100%',
				height: '100%',
				maxHeight: null,
			});
		}
	}, [orientation]);

	const handleMouseMove = (e) => {
		if (e.target.id !== identifier && e.target.id !== `${identifier}-img`) {
			return;
		}
		const { left, top, width, height } = e.target.getBoundingClientRect();
		const x = ((e.pageX - left) / width) * 100;
		const y = ((e.pageY - top) / height) * 100;
		setStyle({
			...style,
			backgroundImage: `url(${src})`,
			backgroundSize: `${zoomIncrement}%`,
			backgroundPosition: `${x}% ${y}%`,
		});
	};

	const onMouseLeave = () => {
		setStyle({
			...style,
			backgroundImage: null,
		});
	};

	let onClick = () => {};

	let classNames = [];
	let imageClassNames = '';
	if (isActive) {
		classNames.push(styles['image-container']);
	}

	if (
		article &&
		isAdvert &&
		advert.imagelink &&
		isValidUrl(advert.imagelink)
	) {
		imageClassNames = styles['clickable'];
		onClick = () => {
			window.open(advert.imagelink);
		};
	}

	if (isActive) {
		return (
			<figure
				id={identifier}
				className={classNames.join()}
				onMouseMove={isActive ? handleMouseMove : () => {}}
				onMouseLeave={onMouseLeave}
				onClick={(e) => {
					if (e.target === e.currentTarget) {
						onClick();
					}
				}}
				style={style}
			>
				<img
					className={imageClassNames}
					id={`${identifier}-img`}
					onClick={onClick}
					draggable={false}
					src={src}
				/>
				<ActionBar
					page={src}
					isZoomPage={isActive}
					direction={direction}
					setIsZoomPage={setIsActive}
				/>
			</figure>
		);
	}

	if (replica) {
		return (
			<figure
				id={identifier}
				className={classNames.join()}
				onMouseMove={isActive ? handleMouseMove : () => {}}
				onMouseLeave={onMouseLeave}
				onClick={(e) => {
					if (e.target === e.currentTarget) {
						onClick();
					}
				}}
				style={style}
			>
				<Page
					name={replica.name}
					page={replica.page}
					hotspots={replica.hotspots}
					hideCanvas={isActive}
					image={replica.image}
					onClickHotspot={onClickHotspot}
				/>
				<ActionBar
					page={src}
					isZoomPage={isActive}
					direction={direction}
					setIsZoomPage={setIsActive}
				/>
			</figure>
		);
	}

	return (
		<figure
			id={identifier}
			className={classNames.join()}
			onMouseMove={isActive ? handleMouseMove : () => {}}
			onMouseLeave={onMouseLeave}
			onClick={(e) => {
				if (e.target === e.currentTarget) {
					onClick();
				}
			}}
			style={style}
		>
			<img
				className={imageClassNames}
				id={`${identifier}-img`}
				onClick={onClick}
				draggable={false}
				src={src}
			/>
			<ActionBar
				page={src}
				isZoomPage={isActive}
				direction={direction}
				setIsZoomPage={setIsActive}
			/>
		</figure>
	);
}

ZoomPageImage.propTypes = {
	src: PropTypes.string,
	direction: PropTypes.oneOf(['left', 'right']),
	identifier: PropTypes.string,
	article: PropTypes.any,
	replica: PropTypes.any,
};

/**
 * @param {any[]} acc
 * @param {{ article: any; page: string; index: number; }} item
 * @param {number} index
 * @param {any[]} array
 */
export function reduceToDoublePages(acc, item, index, array) {
	const { replica } = item;
	if (acc === undefined) {
		acc = [];
	}

	const lastItem = acc[acc.length - 1];
	if (!lastItem) {
		acc.push({
			index: 0,
			article: clone(item.article),
			pageIndex: item.pageIndex,
			pages: [null, item.page],
			replicas: [null, clone(replica)],
		});
		return acc;
	}

	// I fill the page i need add a new one
	if (lastItem.pages.length === 2) {
		const replicas = [clone(item.replica)];
		const pages = [item.page];
		if (index === array.length - 1) {
			pages.push(null);
			replicas.push(null);
		}

		acc.push({
			index: lastItem.index + 1,
			pageIndex: item.pageIndex,
			article: clone(item.article),
			pages,
			replicas,
		});
		return acc;
	}

	lastItem.altArticle = item.article;
	lastItem.pages.push(item.page);
	lastItem.replicas.push(clone(replica));
	acc[acc.length - 1] = lastItem;
	return acc;
}

function isValidUrl(urlString) {
	try {
		return Boolean(new URL(urlString));
	} catch (e) {
		return false;
	}
}

function getPageIndexFromSelectedArticle(selected, data) {
	// http://localhost:3000/issues/drapr_20221031_new1/news-in-brief
	const pageNumber = getPageNumber(selected.pages[0]);

	for (let i = 0; i < data.length; i++) {
		const item = data[i];

		if (item.pages) {
			for (const page of item.pages) {
				if (!page) {
					continue;
				}
				if (getPageNumber(page) === pageNumber) {
					return i;
				}
			}
		}
		if (item.page) {
			if (getPageNumber(item.page) === pageNumber) {
				return i;
			}
		}
	}

	return 0;
}

function getPageNumber(page) {
	const image = page.split('/')[page.split('/').length - 1].split('.')[0];
	if (!/^pg-\d+$/.test(image)) {
		return null;
	}
	const index = parseFloat(image.replace('pg-', ''));
	return index;
}

function clone(obj) {
	return obj ? JSON.parse(JSON.stringify(obj)) : obj;
}
