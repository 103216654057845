import { createContext } from 'react';

export const ArticleContext = createContext({
	device: 'mobile',
	width: null,
	styles: new Map(),
	style: null,
	lang: 'en',
	isSelected: true,
});

export const CanvasContext = createContext({
	paddingLeft: null,
	paddingRight: null,
});
