import React, { useRef, useContext, useEffect } from 'react';

export const ResponsiveModeContext = React.createContext({
	index: 0,
	articles: [],
	article: null,
	setIsFontOpen: null,
	swiper: null,
	player: null,
	lang: 'en',
	onFontClick: null,
	onIsFavoriteClick: null,
	isFavorite: null,
	isOnline: null,
	isFontOpen: null,
	issue: null,
	onSearch: null,
	onTocItemClick: null,
	results: [],
	fontSize: 10,
	onFontSizeChange: null,
	theme: null,
	onArticleNextClick: null,
	onArticlePreviousClick: null,
	isLightboxMode: false,
	width: null,
	pages: [],
	onTransitionEnd: null,
	setSwiper: null,
	onLightboxMode: null,
	isTouchDevice: null,
	onLightboxTargetClick: null,
});

export function useInternalLink() {
	const { article, onTocItemClick, articlesMap } = useContext(
		ResponsiveModeContext
	);
	const contentRef = useRef(null);
	useEffect(() => {
		if (!contentRef) {
			return;
		}
		let nodes = Array.from(contentRef.current.querySelectorAll('a')).filter(
			(node) => {
				let href = node.href
					.replace('https://', '')
					.replace('http://', '')
					.replace(node.host, '')
					.replace(/#[A-Z0-9a-z-]*/gim, '');
				return /\/article\/[0-9]/gim.test(href);
			}
		);
		for (const node of nodes) {
			let href = node.href
				.replace('https://', '')
				.replace('http://', '')
				.replace(node.host, '')
				.replace(/#[A-Z0-9a-z-]*/gim, '');
			const [, , dataArticleId] = href.split('/');
			node.setAttribute('data-article-id', dataArticleId);
			if (articlesMap[dataArticleId]) {
				node.removeAttribute('href');
				node.setAttribute('href', '#');
				node.onclick = () => {
					console.log(`The onclick is: `, articlesMap[dataArticleId]);
					onTocItemClick(articlesMap[dataArticleId]);
				};
			}
		}
	}, [contentRef, article]);

	return {
		contentRef,
	};
}

export function formatTime(seconds) {
	if (isNaN(seconds)) {
		seconds = 0;
	}
	const h = Math.floor(seconds / 3600);
	const m = Math.floor((seconds % 3600) / 60);
	const s = Math.round(seconds % 60);
	return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s]
		.filter(Boolean)
		.join(':');
}
