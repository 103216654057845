import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ArticleContext } from '../Context';
import styles from '../article.module.css';

import { useIsInViewport } from '../hooks/use-viewport';
import { useComponentAnimation } from '../hooks/use-animation';

export const StyledText = ({
	id,
	text,
	text_lang,
	expandfullwidth,
	bleed,
	dropcap,
	component,
	imageurl,
	imagemargin,
	imagewidth,
	imagefloat,
	imageenabled,
	IMAGECAPTION,
	unit,
	animation,
}) => {
	let { lang } = useContext(ArticleContext);
	let content = text;
	if (lang && text_lang?.[lang]) {
		content = text_lang[lang];
	}

	return (
		<Text
			id={id}
			bleed={bleed === 'on'}
			text={content}
			imageCaption={IMAGECAPTION}
			dropcap={dropcap === 'on'}
			expandFullWidth={expandfullwidth === 'on'}
			imageUrl={imageurl}
			animation={animation}
			unit={unit}
			imageMargin={`${imagemargin}`}
			imageWidth={`${imagewidth}`}
			imageFloat={`${imagefloat}`}
			imageEnabled={imageenabled === 'on'}
			type={component}
		/>
	);
};

StyledText.propTypes = {
	id: PropTypes.string,
	text: PropTypes.string,
	unit: PropTypes.object,
	IMAGECAPTION: PropTypes.string,
	column: PropTypes.bool,
	imageurl: PropTypes.string,
	animation: PropTypes.any,
	imageenabled: PropTypes.string,
	imagemargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	imagewidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	imagefloat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	text_lang: PropTypes.object,
	expandfullwidth: PropTypes.string,
	bleed: PropTypes.string,
	dropcap: PropTypes.string,
	columnStyle: PropTypes.object,
	component: PropTypes.oneOf([
		'headline',
		'title',
		'subtitle',
		'intro',
		'body',
		'crosshead',
		'byline',
		'blockquote',
		'footer',
		'text1',
		'text2',
		'text3',
		'text4',
		'text5',
		'text6',
		'text7',
		'text8',
		'text9',
		'text10',
		'text11',
		'text12',
		'text13',
		'text14',
		'text15',
		'text16',
		'text17',
		'text18',
		'text19',
		'text20',
	]),
};

StyledText.defaultProps = {
	column: false,
	columnStyle: null,
};

export const Text = ({
	id,
	bleed,
	text,
	expandFullWidth,
	type,
	dropcap,
	imageUrl,
	imageMargin,
	imageWidth,
	imageFloat,
	imageEnabled,
	imageCaption,
	unit,
	animation,
}) => {
	const ref = useRef();
	const [animationClasses, setAnimationClasses] = useState([]);
	const classNames = ['component', 'text', type, styles['component']];
	let css = [];
	let image = '';
	let clearer = '';
	const style = {};

	useExternalLink(id, type, 500);

	const hasAnimation = animation && animation.type !== 'none';

	const isInViewport = useIsInViewport(ref, hasAnimation);
	useComponentAnimation(ref, isInViewport, animation, setAnimationClasses);

	if (expandFullWidth) {
		style.minWidth = '100%';
		style.width = '100%';
	}

	if (text === '<p></p>') {
		return null;
	}

	if (imageEnabled) {
		if (imageFloat === 'left') {
			imageMargin = `
				margin-right: ${imageMargin}px;
				margin-bottom: ${imageMargin}px;
			`;
		} else {
			imageMargin = `
			margin-left: ${imageMargin}px;
			margin-bottom: ${imageMargin}px;
		`;
		}
		const imageWidthUnit = unit?.['imagewidth'] || 'px';
		css.push(`#${id} figure {
			max-width: ${imageWidth}${imageWidthUnit};
			float: ${imageFloat};
			${imageMargin}
		}`);
		css.push(`#${id} img {
			width: 100%;
		}`);
		if (imageUrl) {
			const caption = imageCaption
				? `<figcaption>${imageCaption}</figcaption>`
				: '';
			style.display = 'block';
			image = `<figure><img src="${imageUrl}" />${caption}</figure>`;
			clearer = '<span style="display: block; clear: both;"></span>';
		}
	}

	if (bleed) {
		classNames.push(bleed);
	}

	if (dropcap) {
		classNames.push('dropcap');
	}

	return (
		<>
			<div
				id={id}
				ref={ref}
				style={style}
				className={[...classNames, ...animationClasses].join(' ')}
				dangerouslySetInnerHTML={{
					__html: `${image} ${text} ${clearer}`,
				}}
			/>
			<style jsx={'true'}>{css.join('\n')}</style>
		</>
	);
};

Text.propTypes = {
	id: PropTypes.string,
	imageCaption: PropTypes.string,
	isSelected: PropTypes.bool,
	unit: PropTypes.object,
	bleed: PropTypes.bool,
	text: PropTypes.PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	expandFullWidth: PropTypes.bool,
	css: PropTypes.string,
	imageEnabled: PropTypes.bool,
	imageUrl: PropTypes.string,
	imageMargin: PropTypes.string,
	imageFloat: PropTypes.string,
	imageWidth: PropTypes.string,
	style: PropTypes.object,
	container: PropTypes.object,
	dropcap: PropTypes.bool,
	animation: PropTypes.any,
	type: PropTypes.oneOf([
		'headline',
		'title',
		'subtitle',
		'intro',
		'body',
		'crosshead',
		'byline',
		'blockquote',
		'footer',
		'text1',
		'text2',
		'text3',
		'text4',
		'text5',
		'text6',
		'text7',
		'text8',
		'text9',
		'text10',
		'text11',
		'text12',
		'text13',
		'text14',
		'text15',
		'text16',
		'text17',
		'text18',
		'text19',
		'text20',
	]),
};

Text.defaultProps = {
	bleed: false,
	expandFullWidth: false,
	css: '',
	style: {},
	dropcap: false,
};

/**
 * React hook that tracks event when an external link is clicked
 *
 * @param id - id of the component
 * @param type - type of text component
 * @param timeout - how long to wait to track the element in the dom
 *
 */
function useExternalLink(id, type, timeout) {
	const { onEventSend } = useContext(ArticleContext);
	useEffect(() => {
		setTimeout(() => {
			document.querySelectorAll(`#${id} a`).forEach((elem) => {
				if (elem.target === '_blank' && elem.href) {
					elem.onclick = () => {
						onEventSend({
							event: 'external',
							data: {
								url: elem.href,
								componentId: id,
								componentType: type,
							},
						});
					};
				}
			});
		}, timeout);
	}, []);
}
