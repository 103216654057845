import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import galleryStyles from '../article.module.css';

import { ArticleContext } from '../Context';
import { Caption } from './Caption';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Pagination } from 'swiper';

import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

export const StyledGallery = ({
	id,
	images,
	captionenabled,
	caption,
	expandfullwidth,
	animation,
	autoplay,
	direction,
	bleed,
	control_speed,
	styles,
}) => {
	const { lang } = useContext(ArticleContext);
	if (typeof caption === 'object') {
		caption = caption[lang] ? caption[lang] : '';
	}

	images = images.map((image) => {
		if (image.caption[lang]) {
			image.caption = image.caption[lang];
		}
		return image;
	});

	return (
		<Gallery
			id={id}
			bleed={bleed === 'on'}
			caption={caption}
			images={images}
			controlSpeed={control_speed}
			styles={styles}
			captionEnabled={captionenabled === 'on'}
			expandFullWidth={expandfullwidth === 'on'}
			animation={animation}
			autoplay={autoplay === 'on'}
			direction={direction}
		/>
	);
};

StyledGallery.propTypes = {
	id: PropTypes.string.isRequired,
	images: PropTypes.array,
	styles: PropTypes.any,
	captionenabled: PropTypes.string,
	caption: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	expandfullwidth: PropTypes.string,
	animation: PropTypes.string,
	autoplay: PropTypes.string,
	direction: PropTypes.string,
	bleed: PropTypes.string,
	control_speed: PropTypes.string,
};

export class Gallery extends React.Component {
	static contextType = ArticleContext;
	interval = null;
	state = {
		index: null,
		swiper: null,
	};
	componentDidMount() {
		const { images, autoplay, controlSpeed } = this.props;
		const total = images.length;
		this.setState({
			index: 0,
			total,
		});
		if (autoplay) {
			let speed = 0;
			switch (controlSpeed) {
				case 'slow':
					speed = 11000;
					break;
				case 'medium':
					speed = 7000;
					break;
				case 'fast':
					speed = 3000;
					break;
				case 'vfast':
					speed = 1500;
					break;
				default:
					speed = 5000;
					break;
			}

			console.log(`Speed: ${speed}`);
			this.interval = setInterval(this.onTic, speed);
		}
	}

	onTic = () => {
		const { autoplay } = this.props;
		let { index, total, swiper } = this.state;
		index = index + 1;
		if (!autoplay) {
			return;
		}

		if (index >= total) {
			this.setState({ index: 0 });
			swiper.slideTo(0, 200);
			return;
		}
		this.setState({ index });
		swiper.slideNext(200);
	};

	componentDidUpdate(prevProps, prevState) {
		const { swiper } = this.state;

		if (swiper && prevProps.direction !== this.props.direction) {
			swiper.changeDirection(this.props.direction);
		}

		if (prevState.swipper === null && swiper) {
			console.log(`I trigger the re render`);
			setTimeout(() => {
				swiper.updateSize();
			}, 500);
		}
	}

	componentWillUnmount() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	onSlideChange = ({ realIndex }) => {
		this.setState({ index: realIndex });
	};

	render() {
		let {
			id,
			images,
			captionEnabled,
			caption,
			animation,
			bleed,
			styles,
			direction,
		} = this.props;

		let { lang } = this.context;

		const dependencies = [Navigation, Pagination];
		if (animation === 'fade') {
			dependencies.push(EffectFade);
		}
		SwiperCore.use(dependencies);
		const { index } = this.state;
		const className = [galleryStyles['gallery'], 'media', 'gallery'];
		if (bleed) {
			className.push('bleed');
		}

		let containerStyle = {};
		if (styles) {
			containerStyle = applyStyles(styles, containerStyle);
		}

		return (
			<div id={id} style={containerStyle} className={className.join(' ')}>
				<Swiper
					effect={animation}
					navigation={true}
					slidesPerView={'auto'}
					updateOnWindowResize={true}
					pagination={{
						clickable: true,
					}}
					onSlideChange={this.onSlideChange}
					onSwiper={(swiper) => {
						swiper.changeDirection(direction);
						setTimeout(() => {
							swiper.update();
						}, 1000);
						this.setState({
							swiper,
						});
					}}
				>
					{images.map((image, i) => {
						const { imageurl, caption } = image;
						const slideClassesName = [
							galleryStyles['gallery-slider'],
						];
						if (i !== index) {
							slideClassesName.push(galleryStyles['hide']);
						}

						let captionText = '';
						if (typeof caption === 'string') {
							captionText = caption;
						} else if (typeof caption === 'object') {
							captionText = caption[lang];
						}

						return (
							<SwiperSlide
								key={i}
								virtualIndex={i}
								className={slideClassesName.join(' ')}
							>
								<div className={galleryStyles['gallery-image']}>
									<img src={imageurl} alt={captionText} />
									<Caption content={caption} />
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
				{captionEnabled && caption ? (
					<Caption content={caption} />
				) : null}
			</div>
		);
	}
}

function applyStyles(styles, containerStyle) {
	containerStyle = clone(containerStyle);
	styles = clone(styles);
	const {
		unit,
		margin_bottom,
		margin_top,
		margin_left,
		margin_right,
		padding_left,
		padding_right,
		padding_top,
		padding_bottom,
	} = styles;
	if (margin_bottom !== undefined && unit['margin_bottom']) {
		containerStyle.marginBottom = `${margin_bottom}${unit['margin_bottom']}`;
	}
	if (margin_top !== undefined && unit['margin_top']) {
		containerStyle.marginTop = `${margin_top}${unit['margin_top']}`;
	}
	if (margin_left !== undefined && unit['margin_left']) {
		containerStyle.marginLeft = `${margin_left}${unit['margin_left']}`;
	}
	if (margin_right !== undefined && unit['margin_right']) {
		containerStyle.marginRight = `${margin_right}${unit['margin_right']}`;
	}

	if (padding_bottom !== undefined && unit['padding_bottom']) {
		containerStyle.paddingBottom = `${padding_bottom}${unit['padding_bottom']}`;
	}
	if (padding_top !== undefined && unit['padding_top']) {
		containerStyle.paddingTop = `${padding_top}${unit['padding_top']}`;
	}
	if (padding_left !== undefined && unit['padding_left']) {
		containerStyle.paddingLeft = `${margin_left}${unit['padding_left']}`;
	}
	if (padding_right !== undefined && unit['padding_right']) {
		containerStyle.paddingRight = `${margin_right}${unit['padding_right']}`;
	}

	return containerStyle;
}

function clone(obj) {
	if (!obj) {
		return obj;
	}
	return JSON.parse(JSON.stringify(obj));
}

Gallery.propTypes = {
	id: PropTypes.string.isRequired,
	images: PropTypes.array,
	styles: PropTypes.any,
	captionEnabled: PropTypes.bool,
	caption: PropTypes.string,
	expandFullWidth: PropTypes.bool,
	animation: PropTypes.string,
	autoplay: PropTypes.bool,
	bleed: PropTypes.bool,
	direction: PropTypes.string,
	controlSpeed: PropTypes.string,
};

Gallery.defaultProps = {
	id: '',
	caption: '',
	captionEnabled: false,
	direction: 'horizontal',
	controlSpeed: 'medium',
};
