import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { ReplicaModeContext } from './ReplicaModeContext';
import styles from './ReplicaMode.module.css';
import { Link } from 'react-router-dom';
import imgLogo from '../../../components/navbar/logo.png';
import { DoublePages, SinglePage, reduceToDoublePages } from './Pages';
import { Toc } from './Toc';
import { Icon } from '../../../components/Icon';
import { AppContext } from '../../../services/AppContext';

export default function ReplicaMode({
	article,
	issue,
	articles,
	onArticleChange,
	lang,
	onClickResponsiveMode,
}) {
	const { deviceType, config, isIframeMode } = useContext(AppContext);
	const [swiper, setSwiper] = useState(null);
	const [selected, setSelected] = useState(article);
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [isTocOpen, setIsTocOpen] = useState(false);
	const [mode, setMode] = useState(
		deviceType === 'mobile' ? 'single' : 'double'
	);
	const [pages, setPages] = useState([]);
	const [pageMap, setPageMap] = useState(null);
	const onClickHotspot = (hotspot) => {
		console.log(`I CLICK HOTSPOT`, hotspot);
		const {url} = hotspot;
		if(url) {
			open(url);
		}
	}
	useEffect(() => {
		const newPageMap = articles
			.filter(filterEmptyReplica)
			.reduce(reduceMapPages, new Map());
		
		setPageMap(newPageMap);

		const pageArticles = articles
			.filter(filterEmptyReplica)
			.reduce(reduceToPages(new Set()), [])
			.sort(sortByPageIndex);
		setPages(pageArticles);
	}, [mode]);

	useEffect(() => {
		if(!selected) {
			return;
		}
		console.log(`CHANGE ARTICLE`, selected);
	}, [selected])

	const onClickToc = () => {
		setIsTocOpen(!isTocOpen);
	};

	const onClickPageMode = () => {
		setSelectedIndex(null);
		setMode(mode === 'single' ? 'double' : 'single');
	};

	useEffect(() => {
		const handler = () => {
			const zoomFactor = getZoomFactor();
			console.log(`Handler zoom factor: ${zoomFactor}`);
			if (zoomFactor > 1) {
				swiper.disable();
			} else {
				swiper.enable();
			}
		};

		if (swiper?.el) {
			swiper?.el?.addEventListener('gestureend', handler);
		}

		// swiper.$el;

		return () => {
			if (swiper?.el) {
				swiper?.el?.removeEventListener('gestureend', handler, true);
			}
		};
	}, [swiper]);

	// If is in an iframe and is not enable return nothing
	if (isIframeMode && !config.iframe.enabled) {
		return null;
	}

	// If iframe is enabled but is only, and im not in iframe return null
	if (config.iframe.enabled && config.iframe.only && !isIframeMode) {
		return null;
	}

	const classNames = [styles['replica-mode']];
	// Is iframe and is enable but the toolbar is not enable
	if (isIframeMode && config.iframe.enabled && !config.iframe.toolbars) {
		classNames.push(styles['no-toolbar']);
	}

	return (
		<ReplicaModeContext.Provider
			value={{
				issue,
				article,
				lang,
				onClickToc,
				onClickPageMode,
				isTocOpen,
				mode,
				pages,
				doublePages: pages.reduce(reduceToDoublePages, []),
				selected,
				setSelected,
				onArticleChange,
				selectedIndex,
				setSelectedIndex,
				onClickResponsiveMode,
				setIsTocOpen,
				swiper,
				setSwiper,
				pageMap,
				onClickHotspot
			}}
		>
			<div className="App">
				<Header />
				<div className={classNames.join(' ')}>
					<Navbar />
					<div className={styles['content']}>
						<Toc />
						<Main selected={selected} />
					</div>
				</div>
			</div>
		</ReplicaModeContext.Provider>
	);
}

ReplicaMode.propTypes = {
	article: PropTypes.any,
	issue: PropTypes.any,
	articles: PropTypes.arrayOf(PropTypes.any),
	lang: PropTypes.string,
	onArticleChange: PropTypes.func,
	onClickResponsiveMode: PropTypes.func,
};

function filterEmptyReplica(article) {
	const {replica} = article;
	if(!replica) {
		return false;
	}

	if(!replica.length) {
		return false;
	}

	return true;
}

function Header() {
	const { article, lang } = useContext(ReplicaModeContext);
	if (!article) {
		return null;
	}

	let title = '';
	if (article?.name && article?.name[lang]) {
		title = article?.name[lang];
	}

	const url = window.location.href;

	const { description, thumbnail } = article;

	return (
		<Helmet>
			{title ? <title>{title}</title> : null}
			{description ? (
				<meta name="description" content={description} />
			) : null}

			{title ? <meta itemProp="name" content={title} /> : null}
			{description ? (
				<meta itemProp="description" content={description} />
			) : null}
			{thumbnail ? <meta itemProp="image" content={thumbnail} /> : null}

			<meta property="og:url" content={url} />
			<meta property="og:type" content="website"></meta>
			{title ? <meta property="og:title" content={title} /> : null}
			{description ? (
				<meta property="og:description" content={description} />
			) : null}
			{thumbnail ? (
				<meta property="og:image" content={thumbnail} />
			) : null}

			<meta name="twitter:card" content="summary_large_image" />
			{title ? <meta name="twitter:title" content={title} /> : null}
			{description ? (
				<meta name="twitter:description" content={description} />
			) : null}
			{thumbnail ? (
				<meta name="twitter:image" content={thumbnail} />
			) : null}
		</Helmet>
	);
}

function Navbar() {
	let { isIframeMode } = useContext(AppContext);
	const { theme, config, locale } = useContext(AppContext);
	const {
		onClickToc,
		onClickPageMode,
		onClickResponsiveMode,
		mode,
		lang,
		pages,
		doublePages,
		selectedIndex,
		selected,
		issue,
	} = useContext(ReplicaModeContext);

	const { color, background } = theme.navbar;

	if (config.iframe.enabled && isIframeMode) {
		if (!config.iframe.toolbars) {
			return null;
		}
	}

	const progress =
		mode === 'single'
			? (100 * (selectedIndex + 1)) / pages.length
			: (100 * (selectedIndex + 1)) / doublePages.length;
	const onShareClick = () => {
		const url = window.location.href;
		const share = {
			title: selected.name[lang],
			text: selected.description || selected.name[lang],
			url,
		};
		if (navigator.share) {
			navigator.share(share);
			return;
		}

		const el = document.createElement('textarea');
		el.value = url;
		el.setAttribute('readonly', '');
		el.style.position = 'absolute';
		el.style.left = '-9999px';
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
		alert(`Share URL has been copied to the clipboard`);
	};
	let externalButton = null;
	if (issue?.externalUrl) {
		externalButton = (
			<button
				onClick={() => {
					window.open(issue?.externalUrl, '_blank', 'download');
				}}
				aria-label={locale['BUTTON_TITLE_EXTERNAL_URL']}
				title={locale['BUTTON_TITLE_EXTERNAL_URL']}
			>
				<Icon name={'external-link'} color={color} />
			</button>
		);
	}
	if (isIframeMode && config.iframe.enabled && !config.iframe.toolbars) {
		return null;
	}
	return (
		<nav className={styles['navbar-container']}>
			<div className={styles['navbar']} style={{ background }}>
				<div>
					<Link to={`/`}>
						<img
							src={imgLogo}
							className={styles['logo']}
							alt={'logo'}
						/>
					</Link>
				</div>
				<div>
					<div className={styles['actions']}>
						<button onClick={onClickToc}>
							<Icon name="toc" color={color} />
						</button>

						{config?.contentType === 'responsive-replica' ? (
							<button onClick={onClickResponsiveMode}>
								<Icon name="responsive-mode" color={color} />
							</button>
						) : null}

						<button onClick={onClickPageMode}>
							<Icon
								name={
									mode === 'single'
										? 'double-page-spread'
										: 'single-page-spread'
								}
								color={color}
							/>
						</button>
						{externalButton}
						{config?.share?.enabled ? (
							<button onClick={onShareClick}>
								<Icon name="share" color={color} />
							</button>
						) : null}
					</div>
				</div>
			</div>
			<div
				className={styles['progress']}
				style={{
					background: theme?.progressBar?.background,
				}}
			>
				<div
					className={styles['bar']}
					style={{
						width: `${progress}%`,
						background: theme?.progressBar?.color,
					}}
				/>
				<div />
			</div>
		</nav>
	);
}

function Main({ selected }) {
	const { pages, mode, doublePages, selectedIndex, swiper } =
		useContext(ReplicaModeContext);

	if (!pages || !selected || !pages.length) {
		return <main className={styles['content']} />;
	}

	let onNextArticle = () => {
		if (swiper) {
			swiper.slideNext();
		}
	};

	let onPreviousArticle = () => {
		if (swiper) {
			swiper.slidePrev();
		}
	};

	if (mode === 'single') {
		return (
			<main className={styles['content']}>
				<NavigationArrow
					direction={'left'}
					disabled={selectedIndex === 0}
					onClick={onPreviousArticle}
				/>
				<NavigationArrow
					direction={'right'}
					disabled={selectedIndex === pages.length - 1}
					onClick={onNextArticle}
				/>
				<SinglePage data={pages} selected={selected} />
			</main>
		);
	}

	if (!doublePages || !doublePages.length) {
		return <main className={styles['content']} />;
	}

	return (
		<main className={styles['content']}>
			<NavigationArrow
				direction={'left'}
				disabled={selectedIndex === 0}
				onClick={onPreviousArticle}
			/>
			<NavigationArrow
				direction={'right'}
				disabled={selectedIndex === doublePages.length - 1}
				onClick={onNextArticle}
			/>
			<DoublePages selected={selected} data={doublePages} />
		</main>
	);
}

Main.propTypes = {
	selected: PropTypes.any,
};

/**
 * @param {any[]} articleSet
 */
function reduceToPages(pagesSet) {
	return (acc, article) => {
		const { replica } = article;
		let index = -1;
		const lastItem = acc[acc.length - 1];
		if (lastItem) {
			index = lastItem.index;
		}

		for (const item of replica) {
			const {image, page, name} = item;
			if (pagesSet.has(name)) {
				continue;
			}
			index += 1;
			pagesSet.add(name);
			acc.push({
				article,
				replica: item,
				index,
				page: image,
				pageIndex: page,
			});
		}

		return acc;
	};
}

function sortByPageIndex(a, b) {
	return a.pageIndex > b.pageIndex ? 1 : -1;
}

// const page = "https://cdn.pwa.canvasflow.io/4043/images/articles/116885/pages/pg-001.jpg"
function reduceMapPages(acc, article) {
	const { replica } = article;
	for(const item of replica) {
		const {page} = item;
		acc.set(page, clone(article));
	}
	return acc;
}

function NavigationArrow({ direction, onClick, disabled }) {
	const { isTocOpen } = useContext(ReplicaModeContext);
	const { isTouchDevice, deviceType } = useContext(AppContext);
	if (isTocOpen) {
		return null;
	}
	if (isTouchDevice || (deviceType === 'mobile' && isTocOpen)) {
		return null;
	}

	const className = [styles['navigation-arrow'], styles[direction]];
	if (disabled) {
		className.push(styles['disabled']);
	}

	return (
		<button
			className={className.join(' ')}
			disabled={disabled}
			onClick={onClick}
		>
			<Icon name={`chevron-${direction}`} color={'#000'} />
		</button>
	);
}

NavigationArrow.propTypes = {
	disabled: PropTypes.bool,
	direction: PropTypes.oneOf(['left', 'right']),
	onClick: PropTypes.func,
};

function getZoomFactor() {
	var deviceWidth,
		landscape = Math.abs(window.orientation) == 90;
	// workaround for strange screen.height on the iPhone (v3.1.3)
	if (window.screen.width == 320) deviceWidth = landscape ? 480 : 320;
	else deviceWidth = window.screen[landscape ? 'height' : 'width'];
	return deviceWidth / window.innerWidth;
}

function clone(obj) {
	if(structuredClone) {
		return structuredClone(obj);
	}
	return obj ? JSON.parse(JSON.stringify(obj)) : obj;
}
