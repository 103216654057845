import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ArticleContext } from '../Context';

export const Caption = ({ content, style }) => {
	const { lang } = useContext(ArticleContext);
	let text = '';
	if (typeof content === 'string') {
		text = content;
	} else if (typeof content === 'object') {
		text = content[lang];
	}
	if(!text) {
		return null;
	}
	if (!text.length) {
		return null;
	}

	if (!style) {
		style = {};
	}
	return (
		<figcaption className="imagecaption" style={style}>
			<span dangerouslySetInnerHTML={{ __html: text }} />
		</figcaption>
	);
};

Caption.propTypes = {
	content: PropTypes.string,
	style: PropTypes.any,
};

Caption.defaultProps = {
	content: '',
	style: {},
};
