import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import styles from '../article.module.css';
import {ArticleContext} from '../Context';
import { useEffect, useRef, useState } from 'react';

export const StyledVideo = ({
	id,
	expandfullwidth,
	vidtype,
	bleed,
	caption,
	captionenabled,
	credit,
	creditenabled,
	vidid,
	moviepath,
	autoplay,
	hostedurl,
	loop,
	aspectRatio,
	controlsenabled,
	linktype,
	weblink,
	params
}) => {
	let { lang } = useContext(ArticleContext);
	if(lang && caption && caption[lang]) {
		caption = caption[lang];
	}

	return (
		<Video
			id={id}
			videoId={vidid}
			type={vidtype}
			bleed={bleed === 'on'}
			hostedUrl={hostedurl}
			expandFullWidth={expandfullwidth === 'on'}
			caption={caption}
			captionEnabled={captionenabled === 'on'}
			credit={credit}
			aspectRatio={aspectRatio}
			creditEnabled={creditenabled === 'on'}
			moviePath={moviepath}
			autoplay={autoplay === 'on'}
			loop={loop === 'on'}
			controlsEnabled={controlsenabled === 'on'}
			linkType={linktype}
			webLink={weblink}
			params={params}
		/>
	);
};

StyledVideo.propTypes = {
	id: PropTypes.string,
	vidid: PropTypes.string,
	expandfullwidth: PropTypes.string,
	vidtype: PropTypes.oneOf(['youtube', 'vimeo', 'movie', 'hosted', 'brightcove']),
	bleed: PropTypes.string,
	caption: PropTypes.string,
	aspectRatio: PropTypes.oneOf(['16by9', '4by3', 'auto']),
	captionenabled: PropTypes.string,
	credit: PropTypes.any,
	creditenabled: PropTypes.string,
	moviepath: PropTypes.string,
	hostedurl: PropTypes.string,
	autoplay: PropTypes.string,
	loop: PropTypes.string,
	controlsenabled: PropTypes.string,
	linktype: PropTypes.string,
	weblink: PropTypes.string,
	params: PropTypes.any
};

export const Video = ({
	id,
	videoId,
	type,
	marginTop,
	marginBottom,
	bleed,
	fullWidth,
	expandFullWidth,
	hostedUrl,
	caption,
	captionEnabled,
	credit,
	creditEnabled,
	moviePath,
	autoplay,
	loop,
	aspectRatio,
	controlsEnabled,
	linkType,
	webLink,
	params
}) => {
	let component = null;
	const containerStyle = {
		marginTop,
		marginBottom,
	};
	const componentStyle = {};
	if (fullWidth) {
		containerStyle.minWidth = '100%';
		containerStyle.width = '100%';
	}

	if (expandFullWidth) {
		componentStyle.minWidth = '100%';
		componentStyle.width = '100%';
	}

	switch (type) {
		case 'youtube':
			component = (
				<iframe
					width="100%"
					title={'Video'}
					height="480"
					style={componentStyle}
					src={`https://www.youtube.com/embed/${videoId}?rel=0&enablejsapi=1`}
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				/>
			);
			break;
		case 'vimeo':
			component = (
				<iframe
					title={'Video'}
					src={`https://player.vimeo.com/video/${videoId}`}
					width="100%"
					height="480"
					style={componentStyle}
					frameBorder="0"
					allow="autoplay; fullscreen"
					allowFullScreen
				/>
			);
			break;
		case 'brightcove':
			console.log(`PARAMS`, params);
			if(params?.accountID && params?.videoID) {
				component = <iframe 
					src={`https://players.brightcove.net/${params.accountID}/default_default/index.html?videoId=${params.videoID}`}
					allowFullScreen
					className={styles['brightcove']}
					allow="encrypted-media"/>
			}	
		  	break;
		case 'hosted':
		case 'movie':
			component = (
				<Movie
					autoplay={autoplay}
					loop={loop}
					controls={controlsEnabled}
					style={componentStyle}
					url={type === 'hosted' ? hostedUrl : moviePath}
				/>
			);
			break;
	
	}

	const className = [styles['video'], 'media', 'video'];
	if (bleed && !fullWidth) {
		className.push('bleed');
	}

	if (aspectRatio === 'auto') {
		className.push(styles['auto']);
	}

	if ((linkType === 'web' || linkType === 'page') && webLink) {
		return (
			<div id={id} className={className.join(' ')} style={containerStyle}>
				<a href={webLink}>{component}</a>
				{captionEnabled && caption ? (
					<Caption content={caption} />
				) : null}
				{creditEnabled && credit ? <Credit content={credit} /> : null}
			</div>
		);
	}

	return (
		<div id={id} className={className.join(' ')} style={containerStyle}>
			{component}
			{captionEnabled && caption ? <Caption content={caption} /> : null}
			{creditEnabled && credit ? <Credit content={credit} /> : null}
		</div>
	);
};

Video.propTypes = {
	id: PropTypes.string,
	videoId: PropTypes.string,
	type: PropTypes.string,
	bleed: PropTypes.bool,
	fullWidth: PropTypes.bool,
	expandFullWidth: PropTypes.bool,
	caption: PropTypes.string,
	captionEnabled: PropTypes.bool,
	credit: PropTypes.string,
	creditEnabled: PropTypes.bool,
	marginTop: PropTypes.number,
	marginBottom: PropTypes.number,
	moviePath: PropTypes.string,
	hostedUrl: PropTypes.string,
	autoplay: PropTypes.bool,
	loop: PropTypes.bool,
	controlsEnabled: PropTypes.bool,
	aspectRatio: PropTypes.oneOf(['16by9', '4by3', 'auto']),
	linkType: PropTypes.string,
	webLink: PropTypes.string,
	params: PropTypes.any
};

Video.defaultProps = {
	marginTop: 0,
	marginBottom: 0,
};

const Caption = ({ content }) => {
	return <figcaption dangerouslySetInnerHTML={{ __html: content }} />;
};

Caption.propTypes = {
	content: PropTypes.string,
};

Caption.defaultProps = {
	content: '',
};

const Credit = ({ content }) => {
	return (
		<cite
			className={styles['credit']}
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	);
};

Credit.propTypes = {
	content: PropTypes.string,
};

Credit.defaultProps = {
	content: '',
};

const Movie = ({ url, autoplay, loop, controls, style }) => {
	const [opts, setOpts] = useState({});
	const refVideo = useRef(null);
	useEffect(() => {
		const attributes = {};
		if (!refVideo.current) {
			return;
		}
		if (autoplay) {
			attributes.autoPlay = true;
			refVideo.current.defaultMuted = true;
			refVideo.current.muted = true;
			attributes.playsInline = true;
			attributes.preload = 'meta';
		}
		if (controls) {
			attributes.controls = true;
		}
		if (loop) {
			attributes.loop = true;
		}
		setOpts(attributes);
	}, [url, autoplay, loop, controls]);

	if (!url) {
		return null;
	}

	const extension = url.split('.').pop();
	return (
		<video ref={refVideo} {...opts} style={style}>
			<source src={url} type={`video/${extension}`} />
			Sorry, your browser does not support embedded videos.
		</video>
	);
};

Movie.propTypes = {
	url: PropTypes.string,
	autoplay: PropTypes.bool,
	loop: PropTypes.bool,
	controls: PropTypes.bool,
	style: PropTypes.any,
};
