import React from 'react';
import { Tracking } from './Tracking';

export const AppContext = React.createContext({
	api: null,
	db: null,
	isOnline: null,
	theme: null,
	host: '',
	appKey: '',
	width: null,
	tracking: new Tracking(),
	auth: null,
	stripeKey: '',
	locale: {},
	lang: 'en',
	onLangChange: null,
	isPrinting: false,
	isIframeMode: false,
	fontSizeOption: null,
	styles: null,
	tts: null,
	config: null,
	getReadTime: null,
	navArrows: null,
	device: { isTouchable: false },
});
