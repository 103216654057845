import React from 'react';
import PropTypes from 'prop-types';
import styles from '../article.module.css';

import { Timeline } from 'react-twitter-widgets';

export const StyledTwitterTimeline = ({
	id,
	height,
	fixedheight,
	bleed,
	name,
}) => {
	return (
		<TwitterTimeline
			id={id}
			height={parseInt(`${height}`)}
			fixedHeight={fixedheight === 'on'}
			bleed={bleed === 'on'}
			name={name}
		/>
	);
};

StyledTwitterTimeline.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	bleed: PropTypes.string,
	fixedheight: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const TwitterTimeline = ({ id, name, bleed, height, fixedHeight }) => {
	const containerStyle = {};

	if (fixedHeight && height) {
		containerStyle.height = height;
		containerStyle.minHeight = height;
		containerStyle.maxHeight = height;
	}

	const className = [styles['twitter'], 'media'];
	if(bleed) {
		className.push('bleed');
	}

	return (
		<div id={id} style={containerStyle} className={className}>
			<Timeline
				dataSource={{
					sourceType: 'profile',
					screenName: name,
				}}
				options={{
					height: '100%',
				}}
			/>
		</div>
	);
};

TwitterTimeline.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	bleed: PropTypes.bool,
	height: PropTypes.number,
	fixedHeight: PropTypes.bool,
};
