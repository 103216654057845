import React from 'react';
import PropTypes from 'prop-types';
import styles from './article.module.css';

import { CanvasContext } from './Context';

export const Canvas = ({ paddingLeft, paddingRight, children, isAd }) => {
	const classNames = [styles['canvas'], 'canvas'];
	if (isAd) {
		classNames.push(styles['ad']);
	}

	return (
		<div className={classNames.join(' ')}>
			<div
				style={{
					borderBottom: '1px solid transparent',
					overflow: 'hidden',
				}}
			>
				<CanvasContext.Provider
					value={{
						paddingLeft,
						paddingRight,
					}}
				>
					{children}
				</CanvasContext.Provider>
			</div>
		</div>
	);
};

Canvas.propTypes = {
	color: PropTypes.string,
	backgroundColor: PropTypes.string,
	isAd: PropTypes.bool,
	paddingLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	paddingRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	paddingBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	paddingTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

Canvas.defaultProps = {
	color: '#ffffff',
	backgroundColor: '#ffffff',
	paddingTop: 0,
	paddingBottom: 0,
	horizontalPadding: 0,
	isAd: false,
};
