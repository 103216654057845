/* global umami*/
import * as ackeeTracker from 'ackee-tracker';
export class Tracking {
	ga = null;
	ackee = null;
	providers = null;
	constructor(providers) {
		if (providers) {
			const { ga, ackee } = providers;
			this.providers = providers;
			this.ga = ga;
			if (ackee?.domainId && ackee?.server) {
				this.ackee = ackeeTracker.create(ackee?.server, {
					detailed: false,
					ignoreLocalhost: false,
				});
				this.ackee.record(ackee?.domainId);
			}
		}
	}
	pageView = ({ location, title }) => {
		if (!location) {
			location = window.location.pathname;
		}
		console.debug(`Tracking - Page view`, {
			title,
			location,
		});
		if (this.ga) {
			this.ga.pageview(location, undefined, title);
		}
	};

	event = ({ category, action, label, value, event, location }) => {
		console.debug('Tracking - Event', {
			category,
			action,
			label,
			value,
		});
		try {
			if (umami && value && event) {
				console.log({
					label,
					event_value: value,
					event_type: event,
					url: location,
				});
				umami.trackEvent(value, event, location);
			}
		} catch (e) {
			console.error(e);
		}
		// eslint-disable-line

		if (this.ga) {
			this.ga.event({
				category,
				action: event,
				label,
				value,
			});
		}

		if (this.ackee) {
			if (action === 'download-page') {
				console.log(`Ackee Action: "${action}"`);
				// FIXME This is only for Drapers
				const eventId = '076086bb-dab9-45eb-8376-05b11d3cf75c';
				this.ackee.action(
					eventId,
					{
						key: value,
						value: 1,
					},
					() => {
						console.debug(`[Ackee] Download Page: '${value}'`);
					}
				);
			}
		}
	};

	external = ({ url, articleId, issueId, componentId, componentType }) => {
		console.debug('External url', {
			articleId,
			issueId,
			url,
		});
		if (this.ackee) {
			let [baseUrl, params] = url.split('?');
			const queryParams = [];
			if (componentId) {
				queryParams.push(`cf_source_component_id=${componentId}`);
			}
			if (componentType) {
				queryParams.push(`cf_source_component_type=${componentType}`);
			}
			if (componentId) {
				queryParams.push(`cf_source_article=${articleId}`);
			}
			if (issueId) {
				queryParams.push(`cf_source_issue=${issueId}`);
			}

			url = params
				? `${baseUrl}?${params}&${queryParams.join('&')}`
				: `${baseUrl}?${queryParams.join('&')}`;
			// FIXME This is only for Tesco
			const eventId = '509ee139-7949-4eb2-ae59-11ab1d369bf5';
			try {
				this.ackee.action(
					eventId,
					{
						key: url,
						value: 1,
					},
					() => {
						console.debug(`[Ackee] Outbound url: '${url}'`);
					}
				);
			} catch (e) {
				console.error(e);
			}
		}
		if (this.ga) {
			try {
				const ga = this.ga.ga();
				ga('send', 'event', 'outbound', 'click', url, {
					transport: 'beacon',
					hitCallback: function () {
						console.debug(`[GA] Outbound url: '${url}'`);
					},
				});
			} catch (e) {
				console.error(e);
			}
		}
	};
}
