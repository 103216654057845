import React from 'react';
import PropTypes from 'prop-types';

import styles from './lightbox.module.css';
import { Icon } from '../../../Icon';
import { useState } from 'react';
import { Image } from './Image';
import { useEffect } from 'react';

export function Lightbox({ selected, display, components, onClose }) {
    const lightboxClasses = [styles['lightbox']];
    components = flatComponents(components).filter(byLightbox);
    // console.log(`Lightbox total of components: ${components.length}`)
    
    let component = components.map((c, i) => {
        c.index = i;
        return c
    }).filter((component) => {
        return component.id === selected;
    })[0];
    const [index, setIndex] = useState(component ? component.index : null);
    const total = components.length;
    let previous = null;
    let next = null;
    let content = null;

    useEffect(() => {
        component = components.map((c, i) => {
            c.index = i;
            return c
        }).filter((component) => {
            return component.id === selected;
        })[0];
        setIndex(component ? component.index : null);
    }, [selected])

    if (index === null) {
        return null;
    }

    component = components[index];

    if (!display) {
        lightboxClasses.push(styles['hide']);
    } else {
        if (total && index) {
            previous = <button
                className={[styles['arrow'], styles['left']].join(' ')}
                onClick={() => {
                    setIndex(index - 1)
                }}>
                <Icon name={'arrow-left'} color={'#fff'} />
            </button>
        }

        if (total && index < total - 1) {
            next = <button
                className={[styles['arrow'], styles['right']].join(' ')}
                onClick={() => {
                    setIndex(index + 1)
                }}>
                <Icon name={'arrow-right'} color={'#fff'} />
            </button>
        }

        content = render(component)
    }

    return <div className={lightboxClasses.join(' ')}>
        <div className={styles['container']}>
            <div>
                <button onClick={onClose}>
                    <Icon name={'close'} color={'#fff'} />
                </button>
            </div>
            <div className={styles['component']}>
                {content}
            </div>
            {previous}
            {next}
        </div>
    </div>
}

function flatComponents(components) {
    const response = [];
    for(const component of components) {
        if(component.columns) {
            for(const col of component.columns) {
                for(const c of col) {
                    response.push(c);
                }
            }
        } else {
            response.push(component);
        }
    }
    return response;
}

function render(component) {
    if(!component) {
        return null;
    }
    switch (component.component) {
        case 'image':
            return <Image {...component} />;
        default:
            return null;
    }
}

function byLightbox({ component, lightbox }) {
    return component === 'image' && lightbox ;
}

Lightbox.propTypes = {
    selected: PropTypes.string,
    display: PropTypes.bool,
    components: PropTypes.array,
    onClose: PropTypes.func
};