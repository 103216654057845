import React from 'react';
import PropTypes from 'prop-types';
import styles from './ReplicaMode.module.css';
import { Icon } from '../../../components/Icon';
import { useContext } from 'react';
import { AppContext } from '../../../services/AppContext';

export function ActionBar({ page, isZoomPage, setIsZoomPage, direction }) {
	const classNames = [styles['action-bar'], styles[direction]];
	const buttons = [
		<DownloadButton key={0} page={page} />,
		<ZoomInButton
			key={1}
			isActive={isZoomPage}
			setIsActive={setIsZoomPage}
		/>,
	];
	if (!buttons.filter((i) => i).length) {
		return null;
	}
	return <div className={classNames.join(' ')}>{buttons}</div>;
}

ActionBar.propTypes = {
	page: PropTypes.string,
	isZoomPage: PropTypes.bool,
	setIsZoomPage: PropTypes.func,
	direction: PropTypes.oneOf(['left', 'right']),
};

function DownloadButton({ page }) {
	const { config, tracking } = useContext(AppContext);
	if (!config?.downloadReplicaPage?.enabled) {
		return null;
	}
	const fileName = page.split('/')[page.split('/').length - 1];
	return (
		<button onClick={downloadFile({ fileName, page, tracking })}>
			<Icon name={'download'} color={'#fff'} />
		</button>
	);
}

DownloadButton.propTypes = {
	page: PropTypes.string,
};

function ZoomInButton({ isActive, setIsActive }) {
	const { isTouchDevice, config } = useContext(AppContext);
	if (isTouchDevice) {
		return null;
	}

	if (!config?.replica?.zoom?.enabled) {
		return null;
	}

	const classNames = [];
	const onClick = () => {
		setIsActive(!isActive);
	};
	if (isActive) {
		classNames.push(styles['active']);
	}
	return (
		<button className={classNames.join(' ')} onClick={onClick}>
			<Icon name={'zoom-in'} color={'#fff'} />
		</button>
	);
}

ZoomInButton.propTypes = {
	isActive: PropTypes.bool,
	setIsActive: PropTypes.func,
};

function downloadFile({ fileName, page, tracking }) {
	return () => {
		if (tracking) {
			try {
				tracking.event({
					action: 'download-page',
					value: fileName,
				});
			} catch (e) {
				console.error(e);
			}
		}
		fetch(page)
			.then((resp) => resp.blob())
			.then(fromBlobToBase64)
			.then(({ mime, data }) => {
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = `data:${mime};base64,${data}`;
				a.download = fileName; //File name Here
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			})
			.catch((err) => {
				console.error(err);
				alert(`Error downloading "${fileName}" please try again`);
			});
	};
}

function fromBlobToBase64(blob) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.addEventListener('error', (e) => {
			reject(e);
		});
		reader.onloadend = () => {
			const base64 = getBase64StringFromDataURL(reader.result);
			resolve({
				mime: blob.type,
				data: base64,
			});
		};
		reader.readAsDataURL(blob);
	});
}

function getBase64StringFromDataURL(dataURL) {
	return dataURL.replace('data:', '').replace(/^.+,/, '');
}
