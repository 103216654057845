import React from 'react';
import PropTypes from 'prop-types';
import { Howl } from 'howler';

import { AppContext } from '../../../services/AppContext';
import { Article } from '../../../components/canvasflow/article/Article';

import { ResponsiveModeDesktop } from './ResponsiveMode.desktop';
import { ResponsiveModeMobile } from './ResponsiveMode.mobile';
import { ResponsiveModeContext } from './ResponsiveModeContext';
import { Player } from '../../../services/Player';
import { Lightbox } from './Lightbox';

export default class ResponsiveMode extends React.Component {
	static contextType = AppContext;

	state = {
		index: 0,
		fontSize: 62.5,
		isFontOpen: false,
		player: null,
		swiper: null,
		isLightboxMode: false,
		isLightbox: false,
		lightBoxArticle: null,
		lightBoxIndex: null,
		articlesMap: {},
	};

	componentDidMount() {
		let { index, articlesMap } = this.state;
		const { articles, article } = this.props;

		for (let i = 0; i < articles.length; i++) {
			articlesMap[`${articles[i].ArticleID}`] = articles[i];
			if (articles[i].id === article.id) {
				index = i;
			}
		}
		this.getPlayer(article);
		this.setState({
			index,
		});
	}

	setSwiper = (swiper) => {
		this.setState({ swiper });
	};

	componentDidUpdate(prevProps) {
		const { lang, article } = this.props;
		const { player } = this.state;
		if (prevProps.lang !== lang || prevProps.article !== article) {
			if (player) {
				player.close();
			}
			this.setState({ player: null });
			this.getPlayer(article);
		}
	}

	onScaleChange = (scale) => {
		const { disableSwipe } = this.state;
		if (scale > 1) {
			if (!disableSwipe) {
				this.setState({ disableSwipe: true });
			}
		} else {
			if (disableSwipe) {
				this.setState({ disableSwipe: false });
			}
		}
	};

	onLoad = async () => {};

	goBack = () => {
		const { subdirectory } = this.context;
		window.location.href = `${subdirectory}/`;
	};

	onFontClick = () => {
		const { isFontOpen } = this.state;
		this.setState({ isFontOpen: !isFontOpen });
	};

	setIsFontOpen = (value) => {
		this.setState({ isFontOpen: value });
	};

	onLightboxMode = (display) => {
		this.setState({ isLightboxMode: display });
	};

	onLightboxTargetClick = ({ id, resource, target }) => {
		// target 'lightbox' or 'internal'
		console.log({ id, resource, target });
		const { articles, onChangePage, articleIDsMap } = this.props;
		if (target === 'lightbox') {
			const article = articleIDsMap.get(`${id}`);
			if (article) {
				this.setState({
					lightBoxArticle: article,
					lightBoxIndex: article.index,
				});
				this.setState({ isLightbox: true });
			}
			return;
		}

		if (target === 'internal') {
			for (let i = 0; i < articles.length; i++) {
				const article = articles[i];
				const { ArticleID } = article;
				// console.log(`Article: ${ArticleID}`);
				if (`${ArticleID}` === `${id}`) {
					onChangePage({ page: i });
					break;
				}
			}
		}
	};

	byArticle = (currentIndex, issue) => {
		const { textDirection } = issue;
		console.log(`TEXT DIRECTION`, textDirection);
		return (article, index) => {
			const { styles } = this.context;
			const { fontSize, articles } = this.props;
			let { id, style, components, ArticleID } = article;
			if (articles.length > 5) {
				const lastArticleIndex = articles.length - 1;
				if (currentIndex === 0) {
					// First and second page
					if (index !== 0 && index !== 1 && index !== 2) {
						return null;
					}
				} else if (currentIndex === lastArticleIndex) {
					// Last 2 articles
					if (
						index !== lastArticleIndex &&
						index !== lastArticleIndex - 1 &&
						index !== lastArticleIndex - 2
					) {
						return null;
					}
				} else {
					if (
						index !== currentIndex &&
						index !== currentIndex - 1 &&
						index !== currentIndex - 2 &&
						index !== currentIndex + 1 &&
						index !== currentIndex + 2
					) {
						return null;
					}
				}
			}

			return (
				<Article
					id={id}
					index={index}
					textDirection={textDirection}
					style={`${style}`}
					fontSize={fontSize}
					styles={styles}
					ArticleID={ArticleID}
					components={components}
					isSelected={true}
					onLightboxTargetClick={() => {}}
					onLightboxMode={this.onLightboxMode}
				/>
			);
		};
	};

	onTocItemClick = (article) => {
		const { onChangePage, articles } = this.props;
		let page = null;
		for (let i = 0; i < articles.length; i++) {
			if (articles[i].id === article.id) {
				page = i;
				break;
			}
		}
		if (page !== null) {
			onChangePage({ page });
		}
	};

	onArticleNextClick = () => {
		const { onChangePage, articles, article } = this.props;
		let page = null;
		for (let i = 0; i < articles.length; i++) {
			if (articles[i].id === article.id) {
				page = i + 1;
				break;
			}
		}
		if (page !== null) {
			onChangePage({ page });
		}
	};

	onArticlePreviousClick = () => {
		const { onChangePage, articles, article } = this.props;
		let page = null;
		for (let i = 0; i < articles.length; i++) {
			if (articles[i].id === article.id) {
				page = i - 1;
				break;
			}
		}
		if (page !== null) {
			onChangePage({ page });
		}
	};

	onTransitionEnd = ({ index }) => {
		const { onChangePage } = this.props;
		onChangePage({ page: parseInt(`${index}`) });
	};

	onDownloadClick = () => {
		const { isDownloaded } = this.props;
		if (isDownloaded) {
			return;
		}
		if (confirm(`Download\nDo you want to download this issue`)) {
			this.props.onDownloadClick();
		}
	};

	getPlayer = (article) => {
		const { tts } = this.context;
		const { lang } = this.props;
		if (!tts?.enabled || !article || !article?.audio) {
			return;
		}
		const { audio } = article;
		const audioUrl = `${audio}/${lang}.mp3`;

		const setPlayer = (player) => {
			this.setState({ player });
		};

		const sound = new Howl({
			src: [audioUrl],
			volume: 0.7,
			html5: true,
			onload: () => {
				if (sound) {
					setPlayer(new Player(sound));
				}
			},
			onloaderror: () => {
				console.error(`Could not load: ${audioUrl}`);
				setPlayer(null);
			},
		});
	};

	onLightboxCloseClick = () => {
		this.setState({ isLightbox: false });
	};

	componentWillUnmount() {
		const { player } = this.state;
		if (player) {
			player.stop();
		}
	}

	render() {
		const {
			isFontOpen,
			player,
			swiper,
			isLightboxMode,
			isLightbox,
			lightBoxArticle,
			lightBoxIndex,
			articlesMap,
		} = this.state;
		const {
			width,
			isTouchDevice,
			deviceType,
			styles,
			isIframeMode,
			config,
		} = this.context;
		const {
			articles,
			article,
			lang,
			onChangePage,
			issue,
			fontSize,
			onFontSizeChange,
			isFavorite,
			onIsFavoriteClick,
			isOnline,
			isDownloaded,
			isDownloading,
			results,
			isImageModeCapable,
			onImageModeClick,
			onSearch,
			onLanguageSelected,
		} = this.props;

		let index = 0;
		for (let i = 0; i < articles.length; i++) {
			if (articles[i].id === article.id) {
				index = i;
				break;
			}
		}
		const pages = articles.map(this.byArticle(index, clone(issue)));

		// If is in an iframe and is not enable return nothing

		if (isIframeMode && config.iframe && config.iframe.enabled === false) {
			return null;
		}

		// If iframe is enabled but is only, and im not in iframe return null
		if (
			config.iframe &&
			config.iframe.enabled &&
			config.iframe.only &&
			!isIframeMode
		) {
			return null;
		}

		return (
			<ResponsiveModeContext.Provider
				value={{
					results,
					articles,
					articlesMap,
					issue,
					article,
					index,
					pages,
					fontSize,
					lang,
					isTouchDevice,
					width,
					isFontOpen,
					isFavorite,
					swiper,
					isLightboxMode,
					setSwiper: this.setSwiper,
					onTocItemClick: this.onTocItemClick,
					onChangePage,
					onTransitionEnd: this.onTransitionEnd,
					onFontClick: this.onFontClick,
					setIsFontOpen: this.setIsFontOpen,
					onFontSizeChange,
					onIsFavoriteClick,
					isOnline,
					player,
					isDownloaded,
					isDownloading,
					onDownloadClick: this.onDownloadClick,
					onSearch,
					onArticleNextClick: this.onArticleNextClick,
					onArticlePreviousClick: this.onArticlePreviousClick,
					onLightboxMode: this.onLightboxMode,
					onLightboxTargetClick: this.onLightboxTargetClick,
					isImageModeCapable,
					onImageModeClick,
					onLanguageSelected,
				}}
			>
				{deviceType === 'mobile' ? (
					<ResponsiveModeMobile />
				) : (
					<ResponsiveModeDesktop />
				)}
				{isLightbox ? (
					<Lightbox
						article={lightBoxArticle}
						articleStyles={styles}
						index={lightBoxIndex}
						fontSize={fontSize}
						onCloseClick={this.onLightboxCloseClick}
					/>
				) : null}
			</ResponsiveModeContext.Provider>
		);
	}
}

ResponsiveMode.propTypes = {
	issue: PropTypes.object,
	articles: PropTypes.array,
	results: PropTypes.array,
	article: PropTypes.object,
	isFavorite: PropTypes.bool,
	isImageModeCapable: PropTypes.bool,
	fontSize: PropTypes.number,
	lang: PropTypes.string,
	onChangePage: PropTypes.func,
	onFontSizeChange: PropTypes.func,
	onIsFavoriteClick: PropTypes.func,
	isOnline: PropTypes.bool,
	isDownloaded: PropTypes.bool,
	isDownloading: PropTypes.bool,
	onDownloadClick: PropTypes.func,
	onSearch: PropTypes.func,
	articleIDsMap: PropTypes.instanceOf(Map),
	onImageModeClick: PropTypes.func,
	onLanguageSelected: PropTypes.func,
};

function clone(obj) {
	return obj ? JSON.parse(JSON.stringify(obj)) : obj;
}
