import React, { useRef, useEffect, useState, useContext, useCallback, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './replica.module.css';
import { PageContext } from './Replica.context';

export const Page = (props) => {
    const { name, page, hotspots, onClickHotspot, image, hideCanvas } = props;
    const ref = useRef(null);
    const [dimensions, setDimensions] = useState(null);
    const [ctx, setCtx] = useState(null);
    const [hotspotMap, setHotspotMap] = useState(
        null,
    );

    const style = isSafari() ? {
        height: dimensions ? dimensions.height : undefined
    } : {};

    const classNames = [styles['replica-page']];
    if(isSafari()) {
        classNames.push(styles['safari']);
    }

    let onClick = () => {};

    // HACK Remove this later
    if(image === 'https://cdn.pwa.canvasflow.io/4157/images/articles/156494/pages/pg-069.jpg') {
        onClick = () => {
            window.open('https://dan.org/join');
        }
        style.cursor = 'pointer';
    }
    
    return (
        <PageContext.Provider
            value={{
                containerRef: ref,
                name,
                dimensions,
                setDimensions,
                hotspotMap,
                setHotspotMap,
                ctx,
                setCtx,
                onClickHotspot,
                hideCanvas
            }}
        >
            <div
                id={`page-${name}`}
                ref={ref}
                data-page-number={page}
                data-page-name={name}
                style={style}
                onClick={onClick}
                className={classNames.join(' ')}
            >
                <ImageLayer image={image} />
                <HotspotLayerSvg hotspots={hotspots} />
            </div>
        </PageContext.Provider>
    );
};

Page.propTypes = {
    name: PropTypes.string,
    page: PropTypes.number,
    image: PropTypes.string,
    hotspots: PropTypes.array,
    onClickHotspot: PropTypes.any,
    hideCanvas: PropTypes.bool
}

const ImageLayer = ({ image }) => {
    const { 
        setDimensions, 
        containerRef,
        ctx, 
        hotspotMap,
        onClickHotspot, 
        dimensions
    } =useContext(PageContext);
    const onResize = useCallback((target) => {
        const rect = target.getBoundingClientRect();
        if(!dimensions) {
            return;
        }

        if(dimensions.width === rect.width && dimensions.height === rect.height) {
            return;
        }
        setTimeout(() => {
            const rect = target.getBoundingClientRect();
            setDimensions({
                width: rect.width,
                height: rect.height,
            });
        }, isSafari() ? 400 : 0);
    })
    const ref = useResizeObserver(onResize);
    
    useEffect(() => {
        if (!ref || !containerRef) {
            return;
        }

        const { current } = ref;
        if (!current) {
            return;
        }

        setTimeout(() => {
            const rect = current.getBoundingClientRect();
            
            setDimensions({
                width: rect.width,
                height: rect.height,
            });
        }, isSafari() ? 400 : 0);
    }, [ref]);

    const onClick = (e) => {
        if (!ref || !ref.current || !ctx || !hotspotMap) {
            return;
        }
        const { current } = ref;
        const eventLocation = getEventLocation(current, e);
        const pixelData = ctx.getImageData(
            eventLocation.x,
            eventLocation.y,
            1,
            1,
        ).data;
        if (
            pixelData[0] == 0 &&
            pixelData[1] == 0 &&
            pixelData[2] == 0 &&
            pixelData[3] == 0
        ) {
            return;
        }

        const color =
            '#' +
            (
                '000000' + rgbToHex(pixelData[0], pixelData[1], pixelData[2])
            ).slice(-6);
        const hotspot = hotspotMap.get(color);
        if (hotspot) {
            onClickHotspot(hotspot);
        }
    };

    return <img ref={ref} src={image} onClick={onClick} />;
};

ImageLayer.propTypes = {
    image: PropTypes.string
}


const HotspotsLayer = ({ hotspots }) => {
    const {
        hotspotMap,
        setHotspotMap,
        dimensions,
        name,
        ctx,
        setCtx,
        onClickHotspot,
        hideCanvas
    } = useContext(PageContext);
    const ref = useRef(null);

    useEffect(() => {
        if (!ref || !ref.current) {
            return;
        }
        if (!dimensions) {
            return;
        }

        const { current } = ref;

        setCtx(current.getContext('2d'));
    }, [ref, dimensions]);

    useEffect(() => {
        if (!ctx) {
            return;
        }
        if (!dimensions) {
            return;
        }
        const { width, height } = dimensions;
        ctx.clearRect(0, 0, width, height);
        const colors = getColors(hotspots.length);
        const hMap = new Map();

        for (const hotspot of hotspots) {
            const { area } = hotspot;
            const color = colors.pop();
            hMap.set(color, hotspot);
            ctx.fillStyle = color;
            const coordinates = getCoordinates(area, width, height);
            ctx.fillRect(
                coordinates.x,
                coordinates.y,
                coordinates.width,
                coordinates.height,
            );
        }

        setHotspotMap(hMap);
    }, [ctx, dimensions]);

    useEffect(() => {
        if (!hotspotMap) {
            return;
        }
        
    }, [hotspotMap]);

    if (!hotspots.length) {
        return null;
    }

    if (!dimensions) {
        return null;
    }

    const onMouseMove = (e) => {
        if (!ref || !ref.current || !ctx || !hotspotMap) {
            return;
        }
        const { current } = ref;
        const eventLocation = getEventLocation(current, e);
        const pixelData = ctx.getImageData(
            eventLocation.x,
            eventLocation.y,
            1,
            1,
        ).data;

        if (
            pixelData[0] == 0 &&
            pixelData[1] == 0 &&
            pixelData[2] == 0 &&
            pixelData[3] == 0
        ) {
            current.style.cursor = 'auto';
            return;
        }

        const color =
            '#' +
            (
                '000000' + rgbToHex(pixelData[0], pixelData[1], pixelData[2])
            ).slice(-6);
        const hotspot = hotspotMap.get(color);
        if(hotspot) {
            console.log(`I find a mouse`);
            current.style.cursor = 'pointer';
        }

    }

    const onClick = (e) => {
        if (!ref || !ref.current || !ctx || !hotspotMap) {
            return;
        }
        const { current } = ref;
        const eventLocation = getEventLocation(current, e);
        const pixelData = ctx.getImageData(
            eventLocation.x,
            eventLocation.y,
            1,
            1,
        ).data;

        if (
            pixelData[0] == 0 &&
            pixelData[1] == 0 &&
            pixelData[2] == 0 &&
            pixelData[3] == 0
        ) {
            return;
        }

        const color =
            '#' +
            (
                '000000' + rgbToHex(pixelData[0], pixelData[1], pixelData[2])
            ).slice(-6);
        const hotspot = hotspotMap.get(color);
        if (hotspot) {
            onClickHotspot(hotspot);
        }
    };

    return (
        <canvas
            width={dimensions.width}
            height={dimensions.height}
            id={`canvas-${name}`}
            style={{
                marginLeft: dimensions.marginLeft && dimensions.marginLeft !== "0px" ? dimensions.marginLeft : undefined,
                marginRight: dimensions.marginRight && dimensions.marginRight !== "0px" ? dimensions.marginRight : undefined,
                zIndex: hideCanvas ? -1 : undefined
            }}
            ref={ref}
            onClick={onClick}
            onMouseMove={onMouseMove}
        />
    );
};

HotspotsLayer.propTypes = {
    hotspots: PropTypes.array
}

function HotspotLayerSvg({ hotspots }){
    const { dimensions } = useContext(PageContext);
    if (!dimensions) {
        return null;
    }

    return (
        <svg width={dimensions.width} height={dimensions.height}>
            {hotspots.map((hotspot, i) => (
                <HotspotRec key={i} hotspot={hotspot} />
            ))}
        </svg>
    );
};

HotspotLayerSvg.propTypes = {
    hotspots: PropTypes.array
}

function HotspotRec({ hotspot }){
    const { url } = hotspot;
    const { dimensions, onClickHotspot } = useContext(PageContext);
    if(!url) {
        return null;
    }
    if (!dimensions) {
        return null;
    }
    const { area } = hotspot;
    const { x, y, width, height } = area;
    const onClick = () => {
        onClickHotspot(hotspot);
    };

    return (
        <rect
            x={x * dimensions.width}
            y={y * dimensions.height}
            onClick={onClick}
            width={width * dimensions.width}
            height={height * dimensions.height}
        >
            <title>
                {url}
            </title>
        </rect>
    );
};


HotspotRec.propTypes = {
    hotspot: PropTypes.object
}


function getCoordinates(
    area,
    width,
    height,
) {
    const coordinates  = {
        x: area.x * width,
        y: area.y * height,
        width: area.width * width,
        height: area.height * height,
    };

    return coordinates;
}


function getColors(total){
    const colors = new Set();
    do {
        colors.add(getRandomHexColorCode());
    } while (colors.size !== total);
    return [...colors];
}

function getRandomHexColorCode() {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
}

function getEventLocation(element, event) {
    const rect = element.getBoundingClientRect();
    const mousePos = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top
    };

    return mousePos;
}

function rgbToHex(r, g, b) {
    if (r > 255 || g > 255 || b > 255) {
        throw new Error('Invalid color component');
    }
    return ((r << 16) | (g << 8) | b).toString(16);
}

function isSafari() {
    return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
               navigator.userAgent &&
               navigator.userAgent.indexOf('CriOS') == -1 &&
               navigator.userAgent.indexOf('FxiOS') == -1;
}

export function useResizeObserver(callback) {
    const ref = useRef(null)
  
    useLayoutEffect(() => {
      const element = ref?.current;
  
      if (!element) {
        return;
      }
  
      const observer = new ResizeObserver((entries) => {
        callback(element, entries[0]);
      });
  
      observer.observe(element);
      return () => {
        observer.disconnect();
      };
    }, [callback, ref]);
  
    return ref
  }